import { Pipe, PipeTransform } from '@angular/core';
import { Observable, concat, find, map } from 'rxjs';

import { Actions, RechtFilter, Resources } from '@mp/shared/data-access';
import { UserInfoFacade } from '@mp/shared/profil/data-access';

@Pipe({
  name: 'mpdmHasRequisitionsRights',
  standalone: true,
})
export class HasRequisitionsRightsPipe implements PipeTransform {
  private readonly requisitionsAllRightsResources: Resources[] = [
    Resources.AlleAnforderungen,
    Resources.Anforderungen,
    Resources.EigeneAnforderungen,
  ];

  constructor(private readonly userInfo: UserInfoFacade) {}

  transform(action: Actions): Observable<boolean> {
    return concat(
      ...this.requisitionsAllRightsResources.map((resource) => this.hasResourcePermission(resource, action)),
    ).pipe(
      find((hasPermission) => hasPermission),
      map(Boolean),
    );
  }

  private hasPermission(right: RechtFilter): Observable<boolean> {
    return this.userInfo.watchRecht$(right, false);
  }

  private hasResourcePermission(resource: Resources, action: Actions): Observable<boolean> {
    return this.hasPermission({ resource, action });
  }
}
